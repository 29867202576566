.glow-container {
    --spread: 60;
  }
  
  .glow-card {
    --active: 0.05; /* Reduce the opacity even further for a more subtle effect */
    --start: 0;
    position: relative; /* Ensure the glow effect is positioned relative to the card */
  }
  
  .glow-card:is(:hover, :focus-visible) {
    z-index: 2;
  }
  
  .glows {
    pointer-events: none;
    position: absolute;
    inset: 0;
    filter: blur(calc(var(--blur) * 1px));
    z-index: 1; /* Ensure the glow effect is below the text */
  }
  
  .glows::after,
  .glows::before {
    --alpha: 0;
    content: "";
    background: conic-gradient(
      from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72deg,
      #f626af 144deg,
      #0025ba 216deg,
      #f626af 288deg,
      #fafafa 360deg
    );
    background-attachment: fixed;
    position: absolute;
    inset: -5px;
    border: 8px solid transparent;
    border-radius: 12px;
    mask: linear-gradient(#0000, #0000),
      conic-gradient(
        from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
        #000 0deg,
        #fff,
        #0000 calc(var(--spread) * 1deg)
      );
    mask-composite: intersect;
    mask-clip: padding-box, border-box;
    opacity: var(--active);
    transition: opacity 2s; /* Slow down the transition */
  }
  
  .glow-card::before {
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    content: "";
    border-radius: 12px;
    pointer-events: none;
    background: #84738c;
    background-attachment: fixed;
    border-radius: 12px;
    mask: linear-gradient(#0000, #0000),
      conic-gradient(
        from calc(
          ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg
        ),
        #ffffff26 0deg,
        white,
        #ffffff26 calc(var(--spread) * 2.5deg)
      );
    mask-clip: padding-box, border-box;
    mask-composite: intersect;
    opacity: var(--active);
    transition: opacity 2s; /* Slow down the transition */
  }
  
  .glow-card::after {
    --bg-size: 100%;
    content: "";
    pointer-events: none;
    position: absolute;
    background: conic-gradient(
      from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72deg,
      #f626af 144deg,
      #0025ba 216deg,
      #f626af 288deg,
      #fafafa 360deg
    );
    background-attachment: fixed;
    border-radius: 12px;
    opacity: var(--active, 0);
    transition: opacity 3s; /* Slow down the transition */
    --alpha: 0;
    inset: 0;
    border: 2px solid transparent;
    mask: linear-gradient(#0000, #0000),
      conic-gradient(
        from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg
      ),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
    filter: brightness(1.5);
    mask-clip: padding-box, border-box;
    mask-composite: intersect;
  }
  